<template>
  <div>
    <div class="wrapper">
      <div class="bg-top"></div>
      <div class="bg-logo-wings"></div>
      <div class="bg-logo-20th"></div>
      <div class="bg-bottom"></div>

      <div v-if="!logins">
        <div v-if="!first">
          <div class="logobig">
            <img :src="require('@/assets/img/logo-title.png')" />
          </div>
          <div style="margin: 0 auto; text-align: center">
            <a-button type="primary" @click="Start"> Start </a-button>
          </div>
        </div>
        <div v-else>
          <div class="logo">
            <img :src="require('@/assets/img/logo.png')" />
          </div>
          <div class="underlogo">
            <img :src="require('@/assets/img/underlogo.png')" />
          </div>

          <div class="form-login" v-if="!thanks">
            <a-form :form="form" @submit="submit">
              <a-form-item label="NAME">
                <a-input
                  v-decorator="[
                    'name',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Field ini harus diisi',
                        },
                      ],
                    },
                  ]"
                  autocomplete="off"
                />
              </a-form-item>

              <a-form-item label="EMAIL">
                <a-input
                  v-decorator="[
                    'email',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Field ini harus diisi',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>

              <div style="text-align: center">
                <a-form-item>
                  <a-button type="primary" html-type="submit"> Next </a-button>
                </a-form-item>
              </div>
            </a-form>
          </div>
          <div class="thanks" v-else>
            <img :src="require('@/assets/img/text-thank-you.png')" />
          </div>
        </div>
      </div>

      <div v-else>
        <div class="logo logomini">
          <img :src="require('@/assets/img/logo.png')" />
        </div>

        <div class="underlogo">
          <img :src="require('@/assets/img/underlogo.png')" />
        </div>

        <div>
          <div v-if="chooseChar == null">
            <a-carousel
              arrows
              :dots="false"
              :centerMode="true"
              :adaptiveHeight="true"
              centerPadding="10px"
              :afterChange="currents"
            >
              <div
                slot="prevArrow"
                class="custom-slick-arrow"
                style="
                  left: 38%;
                  z-index: 1;
                  bottom: 25px !important;
                  top: auto;
                "
              >
                <img :src="require('@/assets/img/arrow-left.png')" />
              </div>
              <div
                slot="nextArrow"
                class="custom-slick-arrow"
                style="right: 38%; bottom: 25px !important; top: auto"
              >
                <img :src="require('@/assets/img/arrow-right.png')" />
              </div>
              <div
                v-for="(item, i) in chars"
                :key="i"
                class="sliderimg"
                ref="slides"
              >
                <img :src="require(`@/assets/img/${item.name}`)" />
              </div>
            </a-carousel>

            <div class="textcenterchoose">
              <span class="textchosechararter">choose<br /></span>
              <span class="textchosechararter">Character</span>
            </div>
            <div style="margin: 0 auto; text-align: center">
              <a-button type="primary" @click="choose(currentSelect)"
                >Choose</a-button
              >
            </div>
          </div>

          <div v-else class="notextarea">
            <a-textarea
              v-model="value"
              placeholder="Your Message Here"
              :rows="10"
              @keydown="onKeyDown"
            />
            <div class="textcenterchoose">
              <span class="textchosechararter">Send<br /></span>
              <span class="textchosechararter">Message</span>
            </div>
            <div style="margin: 0 auto; text-align: center">
              <a-button type="primary" @click="send" :loading="loading"
                >Send</a-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@/assets/css/homepage.css" lang="css"></style>
<style src="@/assets/css/responsivehome.css" lang="css"></style>

<script>
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "Form Home" });
  },
  data() {
    return {
      loading: false,
      logins: false,
      first: false,
      value: "",
      currentSelect: 0,
      chooseChar: null,
      thanks: false,
      name: "",
      email: "",
      max: 40,
      chars: [
        {
          char: "Goreng",
          name: "character_goreng.png",
        },
        {
          char: "Korean",
          name: "character_korean.png",
        },

        {
          char: "AyamJerit",
          name: "character_ayam-jerit.png",
        },
        {
          char: "Soto",
          name: "character_soto.png",
        },

        {
          char: "Laksa",
          name: "character_laksa.png",
        },
      ],
    };
  },

  sockets: {
    connect: function () {
      console.log("socket nyambung");
      //console.log(this.$socket.id)
    },

    disconnect() {
      console.log("dc");
    },
  },
  methods: {
    Start() {
      this.first = true;
    },
    send() {
      if (this.value == "") {
        this.$error({
          title: "Peringatan",
          content: "Field komentar tidak boleh kosong",
        });
      } else {
        // this.$socket.emit("UPLOAD", {
        //   ID: this.$socket.id,
        //   NAME: this.name,
        //   EMAIL: this.email,
        //   CHARACTER: this.chooseChar,
        //   MESSAGE: this.value,
        // });

        this.loading = true;
        this.$store
          .dispatch("sendData", {
            name: this.name,
            email: this.email,
            char: this.chooseChar,
            messages: this.value,
          })
          .then((response) => {
            this.loading = false;
            this.thanks = true;
            this.chooseChar = null;
            this.logins = false;
          });
      }
    },
    onKeyDown(evt) {
      if (this.value.length >= this.max) {
        if (evt.keyCode >= 48 && evt.keyCode <= 90) {
          this.$error({
            title: "Peringatan",
            content: "Hanya bisa 40 karakter",
          });
          evt.preventDefault();
          return;
        }
      }
    },
    currents(id) {
      this.currentSelect = id;
    },
    choose() {
      this.chooseChar = this.chars[this.currentSelect].char;
    },
    submit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.logins = true;
          this.name = values.name;
          this.email = values.email;
        }
      });
    },
  },
};
</script>
